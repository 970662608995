import {AbstractControl, ValidationErrors} from '@angular/forms';

export const NOT_EMPTY_ITEM_VALIDATOR_KEY = 'not_empty_item';

export const notEmptyItemValidator = (control: AbstractControl): ValidationErrors | null => {
  const {value} = control;
  if (!value) {
    return {
      [NOT_EMPTY_ITEM_VALIDATOR_KEY]: {
        value,
      },
    };
  }

  const invalid = /^(\s)*$/.test(value);

  return invalid ? {[NOT_EMPTY_ITEM_VALIDATOR_KEY]: {value}} : null;
};
